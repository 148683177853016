<template>
    <div class="account-list">
        <ui-table-skeleton v-if="loading" />
        <template v-else>
            <div class="row g-2 mb-4">
                <div class="col-md-12 col-lg-3">
                    <ui-input id="search" name="search" label="Search accounts" type="text" placeholder="Enter keywords"
                        :debounce="500" v-model:value="filters.q">
                        <template #prepend>
                            <span class="fas fa-search"></span>
                        </template>
                    </ui-input>
                </div>
                <div class="col-md-6 col-lg-auto">
                    <date-range-picker ref="picker" v-model="range" opens="right" time-picker time-picker-24-hours
                        show-dropdowns control-container-class="account-list__date-range-picker"
                        :linked-calendars="false" @update="changeDate">
                        <template #input>
                            <em class="fa fa-fw fa-calendar ml-n1"></em>
                            {{ $dayjs(filters.range.startDate).format('D MMMM YYYY') }} -
                            {{ $dayjs(filters.range.endDate).format('D MMMM YYYY') }}
                            <strong class="caret"></strong>
                        </template>
                    </date-range-picker>
                </div>
                <div class="col-md-6 col-lg-auto">
                    <ui-select id="activeFilter" class="w-100" name="activeFilter" label="Active" option-label="name"
                        option-value="code" chose-option-text="All" v-model:value="filters.active"
                        :options="booleanActive" />
                </div>
                <div class="col-md-6 col-lg-auto">
                    <ui-select id="verifiedFilter" class="w-100" name="verifiedFilter" label="Verified"
                        option-label="name" option-value="code" chose-option-text="All" v-model:value="filters.verified"
                        :options="booleanVerified" />
                </div>
                <div class="col-md-6 col-lg-auto">
                    <ui-select-multiple id="stageFilter" name="stageFilter" label="Stage" class="w-100"
                        option-label="label" option-value="value" v-model:value="filters.stage" :options="stages" />
                </div>
            </div>
            <ui-table class="mb-4" :items="accounts" :fields="fields" :busy="busy" v-model:sort-by="filters.sort_by"
                v-model:sort-type="filters.sort_type" :scopes="['write_accounts']">
                <template #cell(name)="data">
                    <div class="d-flex align-items-center">
                        <img v-if="data.item.country" class="account-list__flag"
                            :src="`https://envia-clients-new.herokuapp.com/flags/${data.item.country.toLowerCase()}.svg`"
                            :alt="data.item.country" />
                        <div>
                            <ui-link v-if="data.item.first_name"
                                :to="{ name: 'accounts-info', query: { id: data.item.id } }">
                                {{ data.item.first_name }} {{ data.item.last_name || '' }}
                            </ui-link>
                            <span v-else class="text-muted fw-bold fst-italic"> - </span>
                            <p class="mb-0">
                                <strong class="account-list__strong">ID: </strong>
                                <i v-if="!data.item.number" class="text-muted">empty</i>
                                {{ data.item.number }}
                            </p>
                            <ui-badge v-if="data.item.name" variant="success" class="align-self-start badge-large">{{ data.item.name }}</ui-badge>
                        </div>
                    </div>
                </template>
                <template #cell(balance)="data">
                    {{ _currency(data.item.balances.amount, data.item.currency, true) }}
                </template>
                <template #cell(payments)="data">
                    {{ _currency(data.item.balances.payments, data.item.currency, true) }}
                </template>
                <template #cell(charges)="data">
                    {{ _currency(data.item.balances.charges, data.item.currency, true) }}
                </template>
                <template #cell(transactions)="data">
                    {{ data.item.transactions.count }}
                </template>
                <template #cell(chargeback_percentage)="data">
                    {{ data.item.chargebacks.percentage }}%
                </template>
                <template #cell(created_at)="data">
                    {{ $dayjs(data.item.created_at).format('L') }}
                    <br />
                    {{ $dayjs(data.item.created_at).format('LT') }}
                </template>
                <template #cell(contact)="data">
                    <div class="account-list__contact-buttons">
                        <contact-links :phone="data.item.phone || null" :email="data.item.email || null" />
                    </div>
                </template>
                <template #cell(tour)="data">
                    <ui-stage-status class="account-list__stage-status" :stage="data.item.stage || 'NA'"
                        @click="$refs.commentsModal.show(data.item.id)" />
                </template>
                <template #cell(status)="data">
                    <ui-status class="fs-13px" :status="data.item.active ? 'active' : 'inactive'" />
                </template>
                <template #cell(verified)="data">
                    <ui-status class="fs-13px" :status="data.item.verified ? 'verified' : 'pending'" />
                </template>
                <template #cell(actions)="data">
                    <b-dropdown variant="none" no-caret toggle-class="border text-muted">
                        <template #button-content>
                            <span class="fas fa-ellipsis-v"></span>
                        </template>
                        <b-dropdown-item @click="$refs.meetModal.show(data.item.email)">
                            Create meeting
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'accounts-info', query: { id: data.item.id } }">
                            Edit
                        </b-dropdown-item>
                        <b-dropdown-item @click="updateVerfiedStatus(data.item.id)">
                            Update verification status
                        </b-dropdown-item>
                        <b-dropdown-item @click="createWithdrawal(data.item.id)" v-if="CAN(['write_withdrawals'])">
                            Create withdrawal
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </ui-table>
            <div class="d-flex justify-content-center">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="mt-3" />
            </div>
            <comments-modal ref="commentsModal" @updateStage="updateStage" :scope="'write_accounts'"/>
            <meet-modal ref="meetModal" />
            <verification-tracking-modal ref="verificationTrackingModal" />
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DateRangePicker from 'daterange-picker-vue3';
import 'daterange-picker-vue3/dist/daterange-picker-vue3.css';

import CommentsModal from '@/components/accounts/CommentsModal.vue';
import ContactLinks from '@/components/ui/ContactLinks';
import MeetModal from '@/components/accounts/MeetModal.vue';
import UiInput from '@/components/ui/Input.vue';
import UiLink from '@/components/ui/Link.vue';
import UiPagination from '@/components/ui/Pagination.vue';
import UiSelectMultiple from '@/components/ui/inputs/SelectMultiple';
import UiSelect from '@/components/ui/Select';
import UiStageStatus from '@/components/ui/StageStatus';
import UiStatus from '@/components/ui/BadgeStatus.vue';
import UiTable from '@/components/ui/Table.vue';
import UiTableSkeleton from '@/components/ui/skeleton/TableSkeleton.vue';
import VerificationTrackingModal from '@/components/accounts/VerificationTrackingModal.vue';
import UiBadge from '@/components/ui/Badge';

import table_mixin from '@/mixins/tables.mixin';

import { stages } from '@/data';

export default {
    components: {
        CommentsModal,
        ContactLinks,
        DateRangePicker,
        MeetModal,
        UiInput,
        UiLink,
        UiPagination,
        UiSelectMultiple,
        UiSelect,
        UiStageStatus,
        UiStatus,
        UiTable,
        UiTableSkeleton,
        VerificationTrackingModal,
        UiBadge,
    },
    mixins: [table_mixin],
    data() {
        const range = {
            startDate: this.$dayjs()
                .subtract(1, 'year')
                .startOf('day')
                .toDate(),
            endDate: this.$dayjs()
                .endOf('day')
                .toDate(),
        };
        return {
            accounts: null,
            busy: false,
            fields: [
                {
                    key: 'name',
                    label: 'Name'
                },
                {
                    key: 'balance',
                    label: 'Balance',
                    sortable: true,
                    tdClass: 'text-end',
                    thClass: 'text-end',
                },
                {
                    key: 'payments',
                    label: 'Payments',
                    sortable: true,
                    tdClass: 'text-end',
                    thClass: 'text-end',
                },
                {
                    key: 'charges',
                    label: 'Charges',
                    sortable: true,
                    tdClass: 'text-end',
                    thClass: 'text-end',
                },
                {
                    key: 'transactions',
                    label: 'Transactions',
                    sortable: true,
                    tdClass: 'text-end',
                    thClass: 'text-end',
                },
                {
                    key: 'chargeback_percentage',
                    label: 'Chargebacks(%)',
                    tdClass: 'text-end',
                    thClass: 'text-end',
                },
                {
                    key: 'created_at',
                    label: 'Registration',
                    tdClass: 'text-end',
                    thClass: 'text-end',
                },
                {
                    key: 'contact',
                    label: 'Contact',
                },
                {
                    key: 'tour',
                    label: 'Tour',
                },
                {
                    key: 'status',
                    label: 'Status',
                },
                {
                    key: 'verified',
                    label: 'Verification',
                },
                {
                    key: 'actions',
                    label: 'Actions',
                },
            ],
            filters: {
                active: undefined,
                'created_at[from]': '',
                'created_at[to]': '',
                limit: 50,
                page: 1,
                q: '',
                range: { ...range },
                sort_by: null,
                sort_type: null,
                stage: '',
                verified: undefined,
            },
            today: this.dateFormat(new Date()),
            inititialLoading: true,
            loading: false,
            range: { ...range },
            total_pages: null,
            booleanActive: [
                {code: 'true', name: 'Active'},
                {code: 'false', name: 'Not active'},
            ],
            booleanVerified: [
                {code: 'true', name: 'Verified'},
                {code: 'false', name: 'Not verified'},
            ],
        };
    },
    computed: {
        ...mapGetters({
            country: 'globalFilters/country',
        }),
        stages() {
            return stages;
        },
    },
    watch: {
        filters: {
            immediate: false,
            handler() {
                this.getAccounts();
            },
            deep: true,
        },
        country() {
            this.getAccounts();
        },
    },
    methods: {
        changeDate(data) {
            this.filters.range = data;
        },
        dateFormat(fecha) {
            const year = fecha.getFullYear();
            const month = (fecha.getMonth() + 1).toString().padStart(2, '0');
            const day = fecha.getDate().toString().padStart(2, '0');     
            return `${year}-${month}-${day}`;
        },
        clearFilterDate() {
            this.filters['created_at[from]'] = '';
            this.filters['created_at[to]'] = '';
            this.filters.active = undefined;
            this.filters.verified = undefined;
        },
        updateStage({ id, stage }) {
            const searchIndex = this.accounts.findIndex(item => item.id === id);
            
            this.accounts[searchIndex].stage = stage;
        },
        async getAccounts() {
            try {
                if (this.inititialLoading) {
                    this.loading = true;
                }

                this.busy = true;
                const formattedFilters = JSON.parse(JSON.stringify(this.filters));
                const params = {
                    active: this.filters.active,
                    'created_at[from]': this.$dayjs(formattedFilters.range.startDate).toISOString(),
                    'created_at[to]': this.$dayjs(formattedFilters.range.endDate).toISOString(),
                    limit: this.filters.limit,
                    page: this.filters.page,
                    sort_by: this.filters.sort_by,
                    sort_type: this.filters.sort_type,
                    stage: this.filters.stage,
                    verified: this.filters.verified,
                };

                this._clearFilters(params);

                if (String(this.filters.q).trim() !== '') {
                    params.q = this.filters.q;
                }

                if (this.country) {
                    params.country = this.country;
                }

                const { data } = await this.$axios.get('/_/accounts', { params });
                this.total_pages = data.pages;
                this.accounts = data.docs;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                if (this.inititialLoading) {
                    this.loading = false;
                    this.inititialLoading = false
                }

                this.busy = false;
            }
        },
        async updateVerfiedStatus(account_id){
            try {
                this.busy = true;
                await this.$axios.put(`/_/accounts/verifications/update/${account_id}`);
                this.$toast.success('Status updated successfully.');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.busy = false;
                this.getAccounts();
            }
        },
        createWithdrawal(account_id) {
            this.$axios.post('/_/withdrawals', { account_id })
            .then(() => {
                this.$toast.success();
            })
            .catch(error => {
                this.$toast.error(this.getErrorMessage(error));
            });
        }
    },
};
</script>

<style lang="scss">
.account-list {
    &__flag {
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(#000000, 0.15);
        height: 24px;
        margin-right: 8px;
        width: 24px;
    }

    &__strong {
        font-weight: 600;
        color: $general-black;
    }

    &__contact-buttons {
        display: flex;
        gap: 5px;
    }

    &__contact-button {
        align-items: center;
        background-color: #eeeeee;
        border-radius: 4px;
        color: $white !important;
        display: flex;
        font-size: 16px;
        height: 32px;
        justify-content: center;
        line-height: 1;
        padding: 8px;
        text-decoration: none;
        transition: 300ms all;
        width: 32px;

        &--whatsapp {
            background-color: #2ecc71;
            font-size: 18px;

            &:hover {
                background-color: rgba(#2ecc71, 0.8);
            }
        }

        &--phone {
            background-color: #0283cc;

            &:hover {
                background-color: rgba(#0283cc, 0.8);
            }
        }

        &--email {
            background-color: #ffb136;

            &:hover {
                background-color: rgba(#ffb136, 0.8);
            }
        }
    }

    &__verified-badge {
        cursor: pointer;
    }

    &__stage-status {
        cursor: pointer;
    }

    &__date-range-picker {
        align-items: center;
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        height: 40px;
        padding: 4.5px 8px;
        width: 100%;

        .caret {
            margin-left: auto;

            &::before {
                color: $ecart-accent;
                content: '\f078';
                font-size: 16px;
                line-height: 1;
            }
        }
    }

    .vue-daterange-picker {
        width: 100%;
    }
}
</style>
