<template>
    <ui-modal
        ref="commentsModal"
        class="meet-modal"
        title="Account comments"
        size="lg"
        centered
        @hidden="clearForm"
    >
        <p>
            To register a comment it is necessary to complete the following information.
        </p>
        <comments v-if="accountId" :is-lead="isLead" :account-id="accountId"  @updateStage="$emit('updateStage', $event)" :scope="`${scope}`"/>
        <template #footer>
            <ui-button variant="light" @click="hide">
                Close
            </ui-button>
        </template>
    </ui-modal>
</template>

<script>
import Comments from '@/components/accounts/Comments.vue';
import UiButton from '@/components/ui/buttons/Button';
import UiModal from '@/components/ui/Modal';

export default {
    name: 'CommentsModal',  
    components: {
        Comments,
        UiButton,
        UiModal,
    },
    props: {
        isLead: {
            type: Boolean,
            required: false,
            default: false,
        },
        scope: {
            type: String,
            required: true,
            default: null,
        }
    },
    data() {
        return {
            modal: null,
            accountId: null,
        };
    },
    mounted() {
        this.modal = this.$refs.commentsModal;
    },
    methods: {
        show(account_id) {
            this.accountId = account_id;
            this.modal.show();
        },
        hide() {
            this.modal.hide();
        },
        clearForm() {
            this.accountId = null;
        },
    },
};
</script>
