<template>
    <div class="ui-text-area">
        <div v-if="!!$slots.prepend" class="ui-text-area__prepend">
            <slot name="prepend"></slot>
        </div>
        <div
            class="ui-text-area__container"
            :class="{ 'ui-text-area__container--has-prepend': !!$slots.prepend }"
        >
            <textarea
                :id="id"
                :name="name"
                :placeholder="placeholder"
                :rows="rows"
                :value="value"
                class="ui-text-area__input"
                novalidate="true"
                @input="$emit('update:value', $event.target.value)"
            ></textarea>
            <label :for="id" class="ui-text-area__label">
                {{ label }}
            </label>
        </div>
    </div>
    <div v-if="field && field.$error" class="text-danger">
        {{ field.$errors[0].$message }}
    </div>
</template>

<script>
export default {
    name: 'UiTextArea',
    props: {
        field: {
            type: Object,
            default: null,
            required: false,
        },
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: ' ',
            required: false,
        },
        rows: {
            type: Number,
            default: 4,
            required: false,
        },
        value: {
            type: String,
            default: '',
            required: true,
        },
    },
};
</script>

<style lang="scss">
.ui-text-area {
    display: flex;

    &__prepend {
        background-color: $ecart-primary;
        border-radius: 8px 0px 0px 8px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4.5px 8px;
    }

    &__container {
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        padding: 4.5px 8px;
        width: 100%;

        &:focus-within {
            border-color: $ecart-secondary-200;
            box-shadow: none;
        }

        &--valid {
            border-color: $general-success;

            &:focus-within {
                border-color: $general-success;
                box-shadow: none;
            }
        }

        &--invalid {
            border-color: $general-error;

            &:focus-within {
                border-color: $general-error;
                box-shadow: none;
            }
        }

        &--has-prepend {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &__label {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        color: $ecart-secondary-400;
        line-height: 1;
        position: absolute;
        left: 8px;
        top: calc(20px - 7px);
        transition: 200ms all;
    }

    &__input {
        background-color: transparent;
        background-image: none;
        border: none;
        border-radius: 0;
        color: $general-black;
        font-size: 14px;
        font-weight: 500;
        height: auto;
        line-height: 1;
        outline: 0;
        padding: 0;
        padding-top: 15px;
        resize: none;
        width: 100%;

        &::placeholder {
            opacity: 0;
            color: $ecart-secondary-400;
            font-weight: 400;
        }

        &:focus {
            border: none;
            background-color: transparent;

            &::placeholder {
                transition: opacity 200ms ease-in;
                opacity: 1;
            }
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active  {
            -webkit-background-clip: text;
        }
    }
}

.ui-text-area__input:focus ~ .ui-text-area__label,
.ui-text-area__input:not(:placeholder-shown) ~ .ui-text-area__label {
    color: $general-black;
    font-size: 12px;
    top: 4px;
}

.ui-text-area__input:focus,
.ui-text-area__input:not(:placeholder-shown) {
    bottom: 4px;
    top: auto;
}
</style>
