<template>
    <ui-modal
        ref="verificationTrackingModal"
        class="verification-tracking-modal"
        title="Verification information"
        size="lg"
        centered
    >
        <div class="verification-tracking-modal__content">
            <ui-card
                background-variant="white"
                border-variant="secondary"
                padding-variant="sm"
                class="mb-4"
                :shadow="false"
            >
                <template #title>
                    <h5 class="verification-tracking-modal__card-title">
                        Customer information
                    </h5>
                </template>
                <div class="row">
                    <div class="col-md-6 mb-lg-3">
                        <h6 class="verification-tracking-modal__item-title">
                            Name
                        </h6>
                        <p class="verification-tracking-modal__item-text">
                            Samuel Galeana
                        </p>
                    </div>
                    <div class="col-md-6 mb-lg-3">
                        <h6 class="verification-tracking-modal__item-title">
                            Email
                        </h6>
                        <p class="verification-tracking-modal__item-text">
                            samuel.galeana@envia.com
                        </p>
                    </div>
                    <div class="col-md-6">
                        <h6 class="verification-tracking-modal__item-title">
                            Phone
                        </h6>
                        <p class="verification-tracking-modal__item-text">
                            777 515 15 40
                        </p>
                    </div>
                    <div class="col-md-6">
                        <h6 class="verification-tracking-modal__item-title">
                            Nationality
                        </h6>
                        <p class="verification-tracking-modal__item-text">
                            Mexican
                        </p>
                    </div>
                </div>
            </ui-card>
            <ui-card
                background-variant="white"
                border-variant="secondary"
                padding-variant="sm"
                :shadow="false"
            >
                <template #title>
                    <h5 class="verification-tracking-modal__card-title">
                        Customer identification
                    </h5>
                </template>
                <div class="row">
                    <div class="col-md-6 mb-lg-3">
                        <h6 class="verification-tracking-modal__item-title">
                            Name
                        </h6>
                        <p class="verification-tracking-modal__item-text">
                            Samuel Galeana Rebolledo
                        </p>
                    </div>
                    <div class="col-md-6 mb-lg-3">
                        <h6 class="verification-tracking-modal__item-title">
                            Identification number
                        </h6>
                        <p class="verification-tracking-modal__item-text">
                            05111999621
                        </p>
                    </div>
                    <div class="col-md-6">
                        <h6 class="verification-tracking-modal__item-title">
                            Registration date
                        </h6>
                        <p class="verification-tracking-modal__item-text">
                            05/07/2023 11:30 AM
                        </p>
                    </div>
                    <div class="col-md-6">
                        <h6 class="verification-tracking-modal__item-title">
                            Expiration date
                        </h6>
                        <p class="verification-tracking-modal__item-text">
                            06/07/2023 11:30 AM
                        </p>
                    </div>
                </div>
            </ui-card>
            <ui-card
                background-variant="white"
                border-variant="secondary"
                padding-variant="sm"
                :shadow="false"
            >
                <template #title>
                    <h5 class="verification-tracking-modal__card-title">
                        Documents
                    </h5>
                </template>
                <div class="row">
                    <div class="verification-tracking-modal__document mb-lg-3">
                        <div class="verification-tracking-modal__document-preview">
                            <i class="fas fa-file-pdf"></i>
                        </div>
                        <div>
                            <h6 class="verification-tracking-modal__document-preview-name">
                                Constitutive Act.pdf
                            </h6>
                            <p class="verification-tracking-modal__document-preview-metadata">
                                Uploaded 01/03/2023 09:30 AM
                            </p>
                        </div>
                        <ui-icon-button
                            class="ms-auto"
                            icon="far fa-eye"
                            link="https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?b=1&s=612x612&w=0&k=20&c=eU56mZTN4ZXYDJ2SR2DFcQahxEnIl3CiqpP3SOQVbbI="
                            target="_blank"
                        />
                    </div>
                    <div class="verification-tracking-modal__document mb-lg-3">
                        <div class="verification-tracking-modal__document-preview">
                            <i class="fas fa-file-pdf"></i>
                        </div>
                        <div>
                            <h6 class="verification-tracking-modal__document-preview-name">
                                Proof of Address.pdf
                            </h6>
                            <p class="verification-tracking-modal__document-preview-metadata">
                                Uploaded 01/03/2023 09:30 AM
                            </p>
                        </div>
                        <ui-icon-button
                            class="ms-auto"
                            icon="far fa-eye"
                            link="https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?b=1&s=612x612&w=0&k=20&c=eU56mZTN4ZXYDJ2SR2DFcQahxEnIl3CiqpP3SOQVbbI="
                            target="_blank"
                        />
                    </div>
                    <div class="verification-tracking-modal__document">
                        <div class="verification-tracking-modal__document-preview">
                            <img
                                class="verification-tracking-modal__document-preview-image"
                                src="https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?b=1&s=612x612&w=0&k=20&c=eU56mZTN4ZXYDJ2SR2DFcQahxEnIl3CiqpP3SOQVbbI="
                                alt="profile"
                            />
                        </div>
                        <div>
                            <h6 class="verification-tracking-modal__document-preview-name">
                                Profile picture.jpg
                            </h6>
                            <p class="verification-tracking-modal__document-preview-metadata">
                                Uploaded 01/03/2023 09:30 AM
                            </p>
                        </div>
                        <ui-icon-button
                            class="ms-auto"
                            icon="far fa-eye"
                            link="https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?b=1&s=612x612&w=0&k=20&c=eU56mZTN4ZXYDJ2SR2DFcQahxEnIl3CiqpP3SOQVbbI="
                            target="_blank"
                        />
                    </div>
                </div>
            </ui-card>
        </div>
        <template #footer>
            <ui-button variant="light" @click="hide">
                Close
            </ui-button>
        </template>
    </ui-modal>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button';
import UiCard from '@/components/ui/Card.vue';
import UiIconButton from '@/components/ui/IconButton.vue';
import UiModal from '@/components/ui/Modal';


export default {
    name: 'VerificationTrackingModal',  
    components: {
        UiButton,
        UiCard,
        UiIconButton,
        UiModal,
    },
    data() {
        return {
            api: null,
        };
    },
    mounted() {
        this.modal = this.$refs.verificationTrackingModal;
    },
    methods: {
        show(email) {
            this.email = email;
            this.modal.show();
        },
        hide() {
            this.modal.hide();
        },
    },
};
</script>

<style lang="scss">
.verification-tracking-modal {
    &__card-title {
        color: $general-black;
        font-size: 1.05rem;
        margin-bottom: 0;
    }

    &__item-title {
        color: $ecart-secondary-500;
        font-weight: 500;
        margin-bottom: 3px;
    }

    &__item-text {
        color: $general-black;
        font-weight: 500;
        margin-bottom: 0;
    }

    &__document {
        display: flex;
    }

    &__document-preview {
        align-items: center;
        background-color: #ffdbd8;
        border-radius: 8px;
        color: #e1574c;
        display: flex;
        font-size: 20px;
        height: 50px;
        justify-content: center;
        margin-right: 18px;
        overflow: hidden;
        width: 50px;
    }

    &__document-preview-image {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }

    &__document-preview-name {
        color: $general-black;
        font-size: 1.03rem;
        font-weight: 600;
        margin-bottom: 5px;
    }

    &__document-preview-metadata {
        color: $ecart-secondary-500;
        font-size: 0.95rem;
        font-weight: 500;
    }
}
</style>
