<template>
    <ui-modal
        ref="chargebacksModal"
        class="meet-modal"
        title="Create Meet appointment"
        size="lg"
        centered
        @hidden="clearForm"
    >
        <div v-if="!authorized" class="meet-modal__empty-state-container">
            <empty-state
                text="To create a Meet appointment, you need to sign in with your Google account."
                img="calendar.svg"
                img-size="md"
            />
            <ui-button class="w-50" variant="primary" @click="signIn">
                Sign In
            </ui-button>
        </div>
        <div v-else>
            <p class="meet-modal__form-description">
                Enter the information requested below.
            </p>
            <div class="row">
                <div class="col-md-12">
                    <ui-input
                        id="title"
                        name="title"
                        class="mb-4"
                        label="Add title"
                        type="text"
                        v-model:value="formData.title"
                        :field="v$.formData.title"
                    />
                </div>
                <div class="col-md-12">
                    <ui-text-area
                        id="description"
                        name="description"
                        class="mb-4"
                        label="Add description"
                        v-model:value="formData.description"
                        :field="v$.formData.description"
                    />
                </div>
                <div class="col-md-4">
                    <ui-input
                        id="date"
                        name="date"
                        class="mb-4"
                        label="Date"
                        type="date"
                        v-model:value="formData.date"
                        :field="v$.formData.date"
                        :min="$dayjs().format('YYYY-MM-DD')"
                    />
                </div>
                <div class="col-md-4">
                    <ui-input
                        id="startTime"
                        name="startTime"
                        class="mb-4"
                        label="Start time"
                        type="time"
                        v-model:value="formData.startTime"
                        :field="v$.formData.startTime"
                    />
                </div>
                <div class="col-md-4">
                    <ui-input
                        id="endTime"
                        name="endTime"
                        class="mb-4"
                        label="End time"
                        type="time"
                        v-model:value="formData.endTime"
                        :field="v$.formData.endTime"
                    />
                </div>
            </div>
            <div class="text-end">
                <ui-button
                    :loading="loading"
                    type="submit"
                    variant="primary"
                    @click="saveEvent"
                >
                    Save
                </ui-button>
            </div>
            <hr class="meet-modal__hr my-4" />
            <div class="meet-modal__events">
                <h6 class="meet-modal__events-title">Upcoming events</h6>
                <div class="meet-modal__event" v-for="event in events" :key="event.id">
                    <img class="meet-modal__event-icon" src="@/assets/img/accounts/logo-google-calendar.svg" alt="Google calendar" />
                    <div class="meet-modal__event-content">
                        <p class="meet-modal__event-summary">
                            {{ event.summary }}
                        </p>
                        <p class="meet-modal__event-date">
                            <template v-if="event.start.dateTime">
                                {{ $dayjs(event.start.dateTime).format('LL, LTS') }}
                            </template>
                            <template v-if="event.start.date">
                                {{ $dayjs(event.start.date).format('LL') }}
                            </template>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <ui-button variant="light" @click="hide">
                Close
            </ui-button>
        </template>
    </ui-modal>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

import EmptyState from '@/components/ui/EmptyState';
import UiButton from '@/components/ui/buttons/Button';
import UiInput from '@/components/ui/Input';
import UiTextArea from '@/components/ui/TextArea';
import UiModal from '@/components/ui/Modal';

import utils from '@/utils/main.utils';

const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
const SCOPES = 'https://www.googleapis.com/auth/calendar';

export default {
    name: 'MeetModal',  
    components: {
        EmptyState,
        UiButton,
        UiInput,
        UiTextArea,
        UiModal,
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            api: null,
            authorized: false,
            email: null,
            events: null,
            formData: {
                title: '',
                description: '',
                date: '',
                startTime: '',
                endTime: '',
            },
            loading: false,
        };
    },
    validations () {
        return {
            formData: {
                title: {
                    required: helpers.withMessage('Title is required.', required),
                    $autoDirty: true,
                },
                description: {
                    $autoDirt: true
                },
                date: {
                    required: helpers.withMessage('Date is required.', required),
                    minValue: helpers.withMessage('Date must be greater than or equal to current date.', (value) => {
                        const today = (new Date()).toDateString();
                        return this.$dayjs(value).isSameOrAfter(today);
                    }),
                    $autoDirty: true,
                },
                startTime: {
                    required: helpers.withMessage('Start time is required.', required),
                    minValue: helpers.withMessage('Start time must be greater than or equal to current time.', (value) => {
                        const current_time = this.$dayjs().second(0).millisecond(0);
                        return this.$dayjs(this.$dayjs(value, 'HH:mm')).isSameOrAfter(current_time);
                    }),
                    $autoDirty: true,
                },
                endTime: {
                    required: helpers.withMessage('End time is required.', required),
                    minValue: helpers.withMessage('End time must be greater than start time.', (value) => {
                        const start_time = this.$dayjs(this.formData.startTime, 'HH:mm');
                        return this.$dayjs(this.$dayjs(value, 'HH:mm')).isAfter(start_time);
                    }),
                    $autoDirty: true,
                },
            },
        };
    },
    created() {
        this.api = window.gapi;
        this.handleClientLoad();
    },
    mounted() {
        this.modal = this.$refs.chargebacksModal;
    },
    methods: {
        show(email) {
            this.email = email;
            this.modal.show();
        },
        hide() {
            this.modal.hide();
        },
        clearForm() {
            this.formData.title = '';
            this.formData.description = '';
            this.formData.date = '';
            this.formData.startTime = '';
            this.formData.endTime = '';
            this.v$.$reset();
        },
        handleClientLoad() {
            this.api.load('client', this.initClient);
        },
        async initClient() {
            await window.gapi.client.init({
                apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
                discoveryDocs: DISCOVERY_DOCS,
            });
        },
        signIn() {
            const tokenClient = window.google.accounts.oauth2.initTokenClient({
                client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
                scope: SCOPES,
                callback: '',
            });

            tokenClient.callback = async (resp) => {
                if (resp.error !== undefined) {
                    throw (resp);
                }

                this.authorized = true;
                await this.getEvents();
            };

            if (window.gapi.client.getToken() === null) {
                tokenClient.requestAccessToken({prompt: 'consent'});
            } else {
                tokenClient.requestAccessToken({prompt: ''});
            }
        },
        handleSignoutClick() {
            const token = window.gapi.client.getToken();

            if (token !== null) {
                window.google.accounts.oauth2.revoke(token.access_token);
                window.gapi.client.setToken(null);

                this.authorized = false;
            }
        },
        async getEvents() {
            try {
                const request = {
                    'calendarId': 'primary',
                    'timeMin': (new Date()).toISOString(),
                    'showDeleted': false,
                    'singleEvents': true,
                    'maxResults': 5,
                    'orderBy': 'startTime',
                };
                const response = await window.gapi.client.calendar.events.list(request);

                this.events = response.result.items;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
        },
        async saveEvent() {
            const validated = await this.v$.$validate();

            if (!validated) return

            try {
                this.loading = true;

                const start_time = this.$dayjs(`${this.formData.date} ${this.formData.startTime}`, 'YYYY-MM-DD HH:mm').toISOString();
                const end_time = this.$dayjs(`${this.formData.date} ${this.formData.endTime}`, 'YYYY-MM-DD HH:mm').toISOString();
                const timezone = 'America/Mexico_City';

                const event = {
                    summary: this.formData.title,
                    description: this.formData.description,
                    start: {
                        dateTime: start_time,
                        timeZone: timezone,
                    },
                    end: {
                        dateTime: end_time,
                        timeZone: timezone,
                    },
                    attendees: [
                        { email: this.email },
                    ],
                    conferenceData: {
                        createRequest: {
                            requestId: utils.makeId(),
                            conferenceSolutionKey: {
                                type: 'hangoutsMeet',
                            },
                        },
                    },
                };

                const { result } = await window.gapi.client.calendar.events.insert({
                    calendarId: 'primary',
                    conferenceDataVersion: 1,
                    resource: event
                });

                this.events.unshift(result);
                this.clearForm();
                this.$toast.success('Event created successfully.');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.meet-modal {
    &__empty-state-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 450px;
        text-align: center;
    }

    &__events-title {
        color: $general-black;
        font-size: 1.1rem;
        margin-bottom: 15px;
    }

    &__event {
        background-color: $general-white;
        border-radius: 3px;
        display: flex;
        padding: 15px 20px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &__event-content {
        width: calc(100% - 45px);
    }

    &__event-icon {
        height: auto;
        margin-right: 15px;
        width: 30px;
    }

    &__event-summary {
        color: $general-black;
        font-weight: 500;
        margin-bottom: 0;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    &__event-date {
        color: $ecart-secondary-500;
        font-size: 0.9rem;
        margin-bottom: 0;
    }

    &__hr {
        opacity: 0.1;
    }

    &__form-description {
        font-weight: 500;
        color: $ecart-secondary-600;
    }
}
</style>
